// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#search-input__parent {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.search-input__parent {
    width: 60%;
    margin: 0 auto;
    /* margin: 0 auto 8rem; */
}

.search-input {
    width: 100%;
    height: 8rem;
    border: 0.5rem solid #fff;
    border-radius: 4.5rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    background-color: transparent;
    font-size: 3rem;
    color: #fff;
    outline: none;
    transition: transform .2s linear;
}

body.rtl .search-input {
    direction: rtl;
}

.search-input::placeholder {
    color: #fff;
}

.search-input:focus, .search-input.active {
    transform: scale(1.1);
}

.founded-items-count {
    text-align: right;
    font-size: 3rem;
    color: #fff;
}

.not-found-message {
    font-size: 4rem;
    color: #fff;
    display: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.not-found-message.visible {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./styles/search.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;IACf,6BAA6B;IAC7B,eAAe;IACf,WAAW;IACX,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#search-input__parent {\n    display: flex;\n    flex-direction: column;\n    justify-items: center;\n    align-items: center;\n}\n\n.search-input__parent {\n    width: 60%;\n    margin: 0 auto;\n    /* margin: 0 auto 8rem; */\n}\n\n.search-input {\n    width: 100%;\n    height: 8rem;\n    border: 0.5rem solid #fff;\n    border-radius: 4.5rem;\n    margin-bottom: 2rem;\n    padding: 0 2rem;\n    background-color: transparent;\n    font-size: 3rem;\n    color: #fff;\n    outline: none;\n    transition: transform .2s linear;\n}\n\nbody.rtl .search-input {\n    direction: rtl;\n}\n\n.search-input::placeholder {\n    color: #fff;\n}\n\n.search-input:focus, .search-input.active {\n    transform: scale(1.1);\n}\n\n.founded-items-count {\n    text-align: right;\n    font-size: 3rem;\n    color: #fff;\n}\n\n.not-found-message {\n    font-size: 4rem;\n    color: #fff;\n    display: none;\n    text-align: center;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n.not-found-message.visible {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
