// class SpeechText {
//   constructor() {
//     this.element = Object.assign(document.createElement("div"), {
//       id: "voiceReader",
//       tabIndex: -1,
//     });

//     document.body.appendChild(this.element);
//   }

//   read(text, addtionalText) {
//     this.element.innerText = "";

//     if (text) this.element.innerText = text;

//     if (addtionalText) this.element.innerText += addtionalText;

//     this.element.blur();
//     this.element.focus();
//   }
// }

// export default new SpeechText();

//--
class SpeechText {
  /**
 * Creates an instance of SpeechText.
 *
 * @constructor
 */
constructor() {
    this.element = Object.assign(document.createElement("div"), {
      id: "voiceReader",
      tabIndex: -1,
    });

    this.vizioVoiceReader = false;

    if (window.VIZIO) {
      document.addEventListener("VIZIO_TTS_ENABLED", function () {
        this.vizioVoiceReader = true;
      });
      document.addEventListener("VIZIO_TTS_DISABLED", function () {
        this.vizioVoiceReader = false;
      });
    }
    document.body.appendChild(this.element);
  }

  /**
 * ${1:Description placeholder}
 *
 * @param {*} text
 * @param {*} addtionalText
 */
read(text, addtionalText) {
    clearTimeout(this.setSpeekTimeout);

    this.element.innerText = "";

    if (text) {
      this.element.innerText = text;
    }

    if (addtionalText) {
      this.element.innerText += addtionalText;
    }

    if (window.VIZIO) {
      window.VIZIO.Chromevox.cancel();
    }
    this.setSpeekTimeout = setTimeout(() => {
      if (this.vizioVoiceReader && text) {
        window.VIZIO.Chromevox.play(text);
      }
    }, 500);

    if (window.OS == "webOS") {
      // speak text by luna service
      window.webOS.service.request("luna://com.webos.service.tts", {
        method: "speak",
        parameters: {
          text: text,
          clear: true,
        },
        onSuccess: function (res) {
       
        },
        onFailure: function (err) {
       
        },
      });
    }

    this.element.blur();
    this.element.focus();
  }
}

export default new SpeechText();
