import Hls from "hls.js";
import Player from "../../pages/player.js";
import { el, getItem, setItem } from "../../utils.js";
import Subtitles from "../subtitles.js";
import GlobalAnalytics from "../../services/globalAnalytics.js";
import Analytics from "../../services/analytics.js";
import { convertTime } from "../../utils.js";
import {
  adsManager,
  load_ima_ads,
  loadAds,
  showImaAds,
  hasAds,
  configAdBreak,
  displayLog
} from "../ads.js";

/**
 * ${1:Description placeholder}
 *
 * @type {*}
 */
let videoTimer = null;
/**
 * ${1:Description placeholder}
 *
 * @type {*}
 */
let fastForwardTimer = null;
/**
 * ${1:Description placeholder}
 *
 * @type {*}
 */
let backForwardTimer = null;

class HlsPlayer {
  constructor(channel, nextChannelUrl, use_hls, isVideoWatchedTime) {
    this.channel = channel;

    Player.isLiveStreaming = channel.is_live_streaming;

    HlsPlayer.current_channel = channel;
    this.nextChannelUrl = nextChannelUrl;
    this.useHls = use_hls;
    HlsPlayer.useHls = use_hls;
    this.isVideoWatchedTime = isVideoWatchedTime;

    const globalAnalytics = new GlobalAnalytics();
    HlsPlayer.analytics = new Analytics(globalAnalytics);
    HlsPlayer.analytics.setVideoSession(this.channel);
    HlsPlayer.analytics.sendEvent("startVideo");
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {number}
 */
  static videoTime = 0;
  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {boolean}
 */
  static isPaused = false;
  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {boolean}
 */
  static isPlaying = false;
  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {string}
 */
  static currentVideoUrl = "";
  static fastForwardCount = 10;
  static backForwardCount = 10;
  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {*}
 */
  static hlsPLayer = null;
  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {*}
 */
  static current_channel;
  static analytics = null;
  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {boolean}
 */
  static useHls = false;

  static showLoading() {
    const video_loader_img = window.appData?.graphic?.loader_image;

    const video_loader = el("div", "video-loader", "video_loader");

    const video_loader_parent = el("div", "loader-parent");

    if (video_loader_img) {
      const video_loader_img_el = el("img", "loader-img");
      video_loader_img_el.src = video_loader_img;
      video_loader_parent.appendChild(video_loader_img_el);
    } else {
      const video_loader_item1 = el("div", "loader-item1");
      const video_loader_item2 = el("div", "loader-item2");

      video_loader_parent.appendChild(video_loader_item1);
      video_loader_parent.appendChild(video_loader_item2);
    }
    video_loader.appendChild(video_loader_parent);

    const video_parent = document.getElementById("video_parent");
    video_parent.appendChild(video_loader);
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @param {*} e
 */
  static seek(e) {
    const video = document.getElementById("video");
    const progress_bar = document.getElementById("progress_bar");
    const progress_bar_inner = document.getElementById("progress_bar_inner");

    const el_width = progress_bar.offsetWidth;
    const el_percentage = (e.offsetX / el_width) * 100;

    if (progress_bar_inner)
      progress_bar_inner.style.width = `${el_percentage}%`;

    video.currentTime = (video.duration / 100) * el_percentage;

    HlsPlayer.videoTime = video.currentTime;
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static replay() {
    const video = document.getElementById("video");
    HlsPlayer.pause();

    HlsPlayer.videoTime = 0;

    const progress_bar_inner = document.getElementById("progress_bar_inner");
    if (progress_bar_inner) progress_bar_inner.style.width = "0%";

    clearTimeout(videoTimer);
    videoTimer = setTimeout(function () {
      video.currentTime = HlsPlayer.videoTime;
      HlsPlayer.play();
    }, 1000);
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @param {*} id
 * @param {*} time
 */
  static addToContinueWatchingList(id, time) {
    const video = document.getElementById("video");

    if (HlsPlayer?.currentVideoUrl !== video.src) return;

    let continueWatchingList = JSON.parse(
      getItem("continueWatchingList") || "[]"
    );

    const foundVideo = continueWatchingList.find((item) => item.id === id);

    if (!foundVideo) {
      continueWatchingList.unshift({
        id,
        time: Math.floor(time),
      });
    } else {
      continueWatchingList.forEach((item) => {
        if (item.id === id) {
          item.time = Math.floor(time);
          continueWatchingList = continueWatchingList.filter(
            (item) => item.id !== id
          );
          continueWatchingList.unshift({
            id,
            time: Math.floor(time),
          });
        }
      });
    }

    setItem("continueWatchingList", JSON.stringify(continueWatchingList));
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @param {*} id
 */
  static removeContinueWatchingList(id) {
    let continueWatchingList = JSON.parse(
      getItem("continueWatchingList") || "[]"
    );

    continueWatchingList = continueWatchingList.filter(
      (item) => item.id !== id
    );

    setItem("continueWatchingList", JSON.stringify(continueWatchingList));
  }

  static pause() {
    const video = document.getElementById("video");

    video.pause();
    HlsPlayer.isPaused = true;

    const play_button_parent = document.querySelector(".play-button__parent");
    play_button_parent.innerHTML = Player.pause_button_icon;
  }

  static play() {
    const video = document.getElementById("video");
    video.play();
    HlsPlayer.isPaused = false;

    const play_button_parent = document.querySelector(".play-button__parent");
    play_button_parent.innerHTML = Player.play_button_icon;
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static stop() {

    const video = document.getElementById("video");
    video.currentTime = 0;
    HlsPlayer.isPaused = true;
    HlsPlayer.isPlaying = false;
    video.pause();

    const play_button_parent = document.querySelector(".play-button__parent");
    play_button_parent.innerHTML = Player.pause_button_icon;

    const progress_bar_inner = document.getElementById("progress_bar_inner");
    if (progress_bar_inner) progress_bar_inner.style.width = "0%";

    const videoCurrentTime =
      document.getElementsByClassName("video-current_time");

    if (videoCurrentTime && videoCurrentTime.length) {
      videoCurrentTime[0].innerHTML = "00:00";
      videoCurrentTime[1].innerHTML = "00:00";
    }
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @type {boolean}
 */
  static isVideoEndedEventSent = false;

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static togglePlay() {
    const video = document.getElementById("video");
    if (video.paused) {
      HlsPlayer.play();
    } else {
      HlsPlayer.pause();
    }
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static next() {
    const video = document.getElementById("video");
    if (HlsPlayer.isPlaying) {
      HlsPlayer.pause();
    }
    clearTimeout(videoTimer);
    if (HlsPlayer.videoTime + HlsPlayer.fastForward() > video.duration) {
      HlsPlayer.videoTime = video.duration;
    } else {
      HlsPlayer.videoTime += HlsPlayer.fastForward();
    }
    HlsPlayer.backForwardCount = 10;
    const progress = (HlsPlayer.videoTime / video.duration) * 100;
    const progress_bar_inner = document.getElementById("progress_bar_inner");
    if (progress_bar_inner) progress_bar_inner.style.width = `${progress}%`;
    HlsPlayer.updateCurrentTime()
    videoTimer = setTimeout(function () {
      video.currentTime = HlsPlayer.videoTime;
      HlsPlayer.play();
      clearTimeout(videoTimer);
    }, 1000);
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static prev() {
    const video = document.getElementById("video");
    if (HlsPlayer.isPlaying) {
      HlsPlayer.pause();
    }
    HlsPlayer.videoTime -= HlsPlayer.backForward();
    if (HlsPlayer.videoTime < 0) HlsPlayer.videoTime = 0
    HlsPlayer.fastForwardCount = 10;
    clearTimeout(videoTimer);
    const progress = (HlsPlayer.videoTime / video.duration) * 100;
    const progress_bar_inner = document.getElementById("progress_bar_inner");
    if (progress_bar_inner) progress_bar_inner.style.width = `${progress}%`;
    HlsPlayer.updateCurrentTime()
    videoTimer = setTimeout(function () {
      video.currentTime = HlsPlayer.videoTime;
      HlsPlayer.play();
      clearTimeout(videoTimer);
    }, 1000);
  }

  static updateCurrentTime() {
    const underProgressCurrentTime = document.getElementById("current_time");
    if (underProgressCurrentTime) {
      underProgressCurrentTime.innerHTML = convertTime(HlsPlayer.videoTime);
    }
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @returns {number}
 */
  static fastForward() {
    if (HlsPlayer.fastForwardCount < 50) {
      fastForwardTimer = setTimeout(function () {
        HlsPlayer.fastForwardCount += 2;
      }, 1000);
    }

    return HlsPlayer.fastForwardCount;
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 * @returns {number}
 */
  static backForward() {
    if (HlsPlayer.backForwardCount < 50) {
      backForwardTimer = setTimeout(function () {
        HlsPlayer.backForwardCount += 2;
      }, 1000);
    }

    return HlsPlayer.backForwardCount;
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static sendVideoEndEvent() {
    displayLog("Requsted sendVideoEndEvent")
    if (!HlsPlayer.isVideoEndedEventSent) {
      displayLog("SENT!")
      HlsPlayer.analytics.sendEvent("endVideo");
      HlsPlayer.isVideoEndedEventSent = true;
    } else {
      displayLog("SENT!")
    }
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static endVideoListener() {
    if (Player.isLiveStreaming)
      return;
    if (!Player.isAdPlaying) {
      HlsPlayer.autoPlayNext();
    }
  }

  /**
 * ${1:Description placeholder}
 */
  detachMedia() {
    this.hls.detachMedia();
  }

  createAdContainer() {
    const current_channel = this.channel;
    if (document.getElementById("ad_parent")) {
      document.getElementById("ad_parent").remove();
    }

    const ad_parent = el("div", "ad-parent", "ad_parent");
    const ad_overlay = el("div", "overlay");

    ad_parent.appendChild(ad_overlay);

    ad_overlay.onclick = function (e) {
      e.preventDefault();
      e.stopPropagation();
    };

    ad_parent.style.position = "fixed";
    ad_parent.style.zIndex = 9;
    ad_parent.style.width = window.innerWidth + "px";
    ad_parent.style.height = window.innerHeight + "px";

    const ad_duration_parent = el(
      "div",
      "ad-duration-parent hidden",
      "ad_duration_parent"
    );

    ad_duration_parent.style.zIndex = 1;

    const ad_content_wrapper = el("div", "ad-content-wrapper");
    const ad_duration_text = el("div", "ad-duration-text", "ad_duration_text");
    const ad_total_count = el("div", "ad-total-count", "ad_total_count");
    const ad_video_image = el("img", "ad-video-image", "ad_video_image");

    const image = new Image();

    image.src = current_channel.thumbnail;

    image.onload = () => {
      ad_video_image.src = current_channel.thumbnail;
    };

    image.onerror = () => {
      ad_video_image.src = appData.graphic.defaultThumbnail;
    };

    ad_duration_parent.appendChild(ad_video_image);

    ad_content_wrapper.appendChild(ad_duration_text);
    ad_content_wrapper.appendChild(ad_total_count);
    ad_duration_parent.appendChild(ad_content_wrapper);

    ad_parent.appendChild(ad_duration_parent);

    document.getElementById("video_parent").appendChild(ad_parent);
  }

  /**
 * ${1:Description placeholder}
 */
  render() {
    const app_loader = document.getElementById("app_loader");
    const video = document.getElementById("video");
    const current_channel = this.channel;

    if (app_loader) app_loader.classList.add("show");

    this.createAdContainer();

    Player.showPlayerControls(false);
    HlsPlayer.currentVideoUrl = current_channel.streamURL;

    if (this.useHls) {
      if (this.hls) {
        this.hls.destroy();
      }

      this.hls = new Hls();
      HlsPlayer.hlsPLayer = this.hls;

      this.hls.attachMedia(video);

      this.hls.loadSource(this.channel?.streamURL);
      video.play();



      video.onloadeddata = () => {
        this.loadHandler();
      };

      video.addEventListener("playing", () => {
        this.onPlaying();
      });

      video.addEventListener("waiting", () => {
        this.videoWaitingHandler();
      });

      video.addEventListener("timeupdate", (e) => {
        if (!Player.isLiveStreaming) {
          this.timeUpdate(e);
        }
      });
    } else {
      const app_loader = document.getElementById("app_loader");
      if (app_loader) app_loader.classList.add("show");

      video.src = this.channel.streamURL;
      try {
        if (this.isVideoWatchedTime && isFinite(this.isVideoWatchedTime)) {
          setTimeout(function () {
            video.currentTime = parseInt(this.isVideoWatchedTime);
          }, 500);
        }
      } catch (error) {
      }
      video.play();
      video.onloadeddata = () => {
        this.loadHandler();
      };

      video.addEventListener("playing", (e) => {
        this.onPlaying();
      });

      video.addEventListener("waiting", () => {
        this.videoWaitingHandler();
      });

      video.addEventListener("timeupdate", (e) => {
        this.timeUpdate(e);
      });
    }
  }

  /**
 * ${1:Description placeholder}
 */
  timeUpdate() {
    const video = document.getElementById("video");
    if (video) {
      const currentTime = video.currentTime;
      const duration = video.duration;
      const progress = (currentTime / duration) * 100;
      const underProgressDuration = document.getElementById("duration_time");
      const underProgressCurrentTime = document.getElementById("current_time");

      if (Player.showSubtitles) {
        Subtitles.setSubtitles(currentTime);
      }

      if (!HlsPlayer.isPaused) {
        const progress_bar_inner =
          document.getElementById("progress_bar_inner");
        HlsPlayer.fastForwardCount = 0;
        HlsPlayer.backForwardCount = 0;
        if (progress_bar_inner) progress_bar_inner.style.width = `${progress}%`;
        HlsPlayer.videoTime = video.currentTime;
      }

      if (underProgressDuration) {
        if (duration) {
          underProgressDuration.innerHTML = convertTime(duration);
        }
      }

      if (underProgressCurrentTime) {
        if (currentTime) {
          underProgressCurrentTime.innerHTML = convertTime(currentTime);
        }
      }
      // if ad avialable, check ad break time. and load the ads to be displayed
      if (hasAds) {
        let current_ad_break_index = -1;
        for (let i = 0; i < Player.ad_breaks.length; i++) {
          let ad_break = Player.ad_breaks[i];
          if (!isNaN(ad_break.time) && currentTime >= ad_break.time && !ad_break.played && currentTime - ad_break.time < 5)
            current_ad_break_index = i;
        }
        if (current_ad_break_index > -1) {
          if (!Player.isAdPlaying)
            load_ima_ads(current_ad_break_index);
        }
      }
    }
  }

  /**
 * ${1:Description placeholder}
 */
  loadHandler() {
    if (hasAds) {
      configAdBreak();
      this.show_ima_ads();
    }
  }

  /**
 * ${1:Description placeholder}
 */
  show_ima_ads() {
    const _this = this;
    if (Player.ad_breaks[0].time == 'start') // if pre roll, then play ad when loaded video
      load_ima_ads(0);
  }

  /**
 * ${1:Description placeholder}
 */
  onPlaying() {
    const app_loader = document.getElementById("app_loader");
    if (app_loader) app_loader.classList.remove("show");

    HlsPlayer.isVideoEndedEventSent = false;

    HlsPlayer.isPaused = false;
    HlsPlayer.isPlaying = true;

    Player.showPlayerControls(false);

    const video_loader = document.getElementById("video_loader");
    if (video_loader) {
      video_loader.remove();
    }
  }

  /**
 * ${1:Description placeholder}
 */
  videoWaitingHandler() {
    const video_loader = document.getElementById("video_loader");
    if (video_loader) {
      video_loader.remove();
      HlsPlayer.isPlaying = false;
    }
    Player.showPlayerControls(true);
    HlsPlayer.showLoading();
    HlsPlayer.fastForwardCount = 0;
  }

  /**
 * ${1:Description placeholder}
 *
 * @static
 */
  static autoPlayNext() {
    try {
      const video = document.getElementById("video");
      if (HlsPlayer.isPlaying) {
        HlsPlayer.pause();
        HlsPlayer.videoTime = 0;
      }

      const progress_bar_inner = document.getElementById("progress_bar_inner");
      if (progress_bar_inner) progress_bar_inner.style.width = "0%";

      clearTimeout(videoTimer);

      const next_button = document.getElementById("next_button");
      next_button.click();

      const id = video.getAttribute("data-id");
      const continueWatchingList = JSON.parse(
        getItem("continueWatchingList") || "[]"
      );

      const index = continueWatchingList.findIndex((item) => item.id === id);
      // check if the video is not ad video

      if (index !== -1) {
        continueWatchingList.splice(index, 1);

        setItem("continueWatchingList", JSON.stringify(continueWatchingList));
      }
    } catch (error) {

    }

  }

  /**
 * ${1:Description placeholder}
 */
  destroy() {
    const app_loader = document.getElementById("app_loader");
    if (app_loader) app_loader.classList.remove("show");
    this.hls.destroy();
  }
}

export default HlsPlayer;
