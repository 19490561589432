/**
 * ${GET:API calls by "GET" method}
 *
 * @param {string} url
 * @param {object} [settings={\}]
 * @property {string} settings.defaultVal   defaultVal of settings'
 * @property {string} settings.type   type of settings'
 * @returns {*\}
 */
export const GET = (url, settings = {}) => {
  return new Promise((resolve, reject) => {
    if (!url) return reject("no url");
    const { defaultVal, type = "json" } = settings;

    const request = new XMLHttpRequest();
    request.responseType = type;

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          //success
          try {
            return resolve(request.response);
          } catch (error) {

            reject(
              `Data received, but there is an additional error, is it a valid File? url: ${url}`
            );
          }
        }

        if (request.status >= 400) {

          resolve(defaultVal);
        }
      }
    };
    request.open("GET", url, true);
    request.send();
  });
};
