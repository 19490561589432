// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hashes-parent {
    width: 100%;
    height: 100vw;
    position: relative;
    background-color: gray;
}

.hashes-wrapper {
    padding: 2rem;
}

.hashes-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.hashes-item {
    width: 32%;
    height: 6.5rem;
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
    line-height: 4rem;
    color: #fff;
    background-color: rgb(70, 69, 69);
    border-radius: .5rem;
    border: .3rem solid transparent
}

.hashes-item.active {
    border-color: #fff;
}`, "",{"version":3,"sources":["webpack://./styles/hashes.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,eAAe;IACf,aAAa;IACb,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;IACX,iCAAiC;IACjC,oBAAoB;IACpB;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".hashes-parent {\n    width: 100%;\n    height: 100vw;\n    position: relative;\n    background-color: gray;\n}\n\n.hashes-wrapper {\n    padding: 2rem;\n}\n\n.hashes-list {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.hashes-item {\n    width: 32%;\n    height: 6.5rem;\n    font-size: 2rem;\n    padding: 1rem;\n    margin-bottom: 0.5rem;\n    line-height: 4rem;\n    color: #fff;\n    background-color: rgb(70, 69, 69);\n    border-radius: .5rem;\n    border: .3rem solid transparent\n}\n\n.hashes-item.active {\n    border-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
