// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keyboard_parent {
    width: 55%;
    height: 32rem;
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 50%; */
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, .9);
    border-radius: .5rem;
    transition: all .2s ease-in-out;
    z-index: 99999999;
}

.keyboard_row {
    display: flex;
    justify-content: space-between;
    margin: .2rem 0;
}

.keyboard_row .keyboard_key {
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
    min-height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #242424;
    color: #fff;
    font-size: 2.2rem;
}

.keyboard_key.active {
    border: 2px solid #fff;
}

.keyboard_key.keyboard_key-space {
    width: 49.2rem;
}

.keyboard_parent.hidden {
    opacity: 0;
    pointer-events: none;
    bottom: -35rem;
}

.keyboard_parent .keyboard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.keyboard_key-done,
.keyboard_key-clean,
.keyboard_key-backspace,
.keyboard_key-123,
.keyboard_key-eng {
    width: 16rem !important;
}
`, "",{"version":3,"sources":["webpack://./styles/keyboard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,wBAAwB;IACxB,eAAe;IACf,eAAe;IACf,2BAA2B;IAC3B,mCAAmC;IACnC,oBAAoB;IACpB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;AACf;;AAEA;;;;;IAKI,uBAAuB;AAC3B","sourcesContent":[".keyboard_parent {\n    width: 55%;\n    height: 32rem;\n    /* position: absolute; */\n    /* bottom: 0; */\n    /* left: 50%; */\n    transform: translateX(-50%);\n    background-color: rgba(0, 0, 0, .9);\n    border-radius: .5rem;\n    transition: all .2s ease-in-out;\n    z-index: 99999999;\n}\n\n.keyboard_row {\n    display: flex;\n    justify-content: space-between;\n    margin: .2rem 0;\n}\n\n.keyboard_row .keyboard_key {\n    width: 8rem;\n    min-width: 8rem;\n    height: 8rem;\n    min-height: 8rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #242424;\n    color: #fff;\n    font-size: 2.2rem;\n}\n\n.keyboard_key.active {\n    border: 2px solid #fff;\n}\n\n.keyboard_key.keyboard_key-space {\n    width: 49.2rem;\n}\n\n.keyboard_parent.hidden {\n    opacity: 0;\n    pointer-events: none;\n    bottom: -35rem;\n}\n\n.keyboard_parent .keyboard {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n}\n\n.keyboard_key-done,\n.keyboard_key-clean,\n.keyboard_key-backspace,\n.keyboard_key-123,\n.keyboard_key-eng {\n    width: 16rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
