/**
 * GoogleAnalytics.
 */
export default class GoogleAnalytics {
  /**
 * Creates an instance of GoogleAnalytics.
 *
 * @constructor
 * @param {*} tag
 */
constructor(tag) {
    this.name = "googleAnalytics";
    this.type = "analytics";
    this.tag = tag;    
  }

  /**
 * gtag loaded status
 *
 * @static
 * @type {boolean}
 */
static gtag_loaded=false;
  /**
 * load google anlaytics
 *
 * @returns {*}
 */
load() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");

      script.id = "googleAnalytics";
      script.src = this.tag.src;


      script.type = "text/javascript";
      script.async = true;
      script.onload = () => resolve(this);
      script.onerror = () => reject(this);

      document.head.appendChild(script);
    });
  }

  configGtag(){
    const url = this.tag.src;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const tagValue = params.get('tag');
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', tagValue);

    GoogleAnalytics.gtag_loaded=true;    
  }

  /**
 * function to send the events to google analytics
 *
 * @param {*} data
 * @returns {*}
 */
sendEvent(data) {
    if(!GoogleAnalytics.gtag_loaded) 
      this.configGtag();    
    const { eventName, parameters } = data;    
    if (!parameters.event_category) {
      return console.error("missing event category");
    }

    window.gtag("event", eventName, parameters);
  }

  /**
 * function to send an error event to google analytics
 *
 * @param {*} description
 * @param {*} fatal
 */
sendError(description, fatal) {
    if(!GoogleAnalytics.gtag_loaded) 
      this.configGtag();  
    window.gtag("event", "exception", {
      description,
      fatal,
    });
  }
}
